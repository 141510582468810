import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ICariSozlesmeler_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ICariSozlesmeler_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  setKartCariSozlesmeBilgileriSave: number;
  setKartCariSozlesmeBilgileriSave_dummy: number;
  GoBack: any;
  GoBack_dummy: any;
  sbSozlesmeTipiList: any[];
  sbSozlesmeTipiList_dummy: any[];
  sbSozlesmeKonuList: any[];
  sbSozlesmeKonuList_dummy: any[];
  sbTemsilBolgesiList: any[];
  sbTemsilBolgesiList_dummy: any[];
  getKartCariSozlesmeBilgi: any[];
  getKartCariSozlesmeBilgi_dummy: any[];
  sbParaBirim: any[];
  sbParaBirim_dummy: any[];
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  spSozlesmeDonemTutarHesapla: any[];
  spSozlesmeDonemTutarHesapla_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  spSozlesmeFaturaDagit: any[];
  spSozlesmeFaturaDagit_dummy: any[];
  Notify: boolean;
  Notify_dummy: boolean;
  UploadFile: any;
  UploadFile_dummy: any;
  TriggerEvent: string;
  TriggerEvent_dummy: string;
  isComp694470Visible: "visible" | "hidden";
  isComp308943Visible: "visible" | "hidden";
  isComp518562Visible: "visible" | "hidden";
  isComp138538Visible: "visible" | "hidden";
  isComp437788Visible: "visible" | "hidden";
  isComp890030Visible: "visible" | "hidden";
  isComp311330Visible: "visible" | "hidden";
  isComp207335Visible: "visible" | "hidden";
  isComp608676Visible: "visible" | "hidden";
  isComp481539Visible: "visible" | "hidden";
  isComp159164Visible: "visible" | "hidden";
  isComp239690Visible: "visible" | "hidden";
  isComp720644Enabled: "enabled" | "disabled";
  isComp701247Enabled: "enabled" | "disabled";
}

export class CariSozlesmeler_ScreenBase extends React.PureComponent<ICariSozlesmeler_ScreenProps, any> {
  carisozlesmeler_186947_value_kuikaSelectBoxRef: React.RefObject<any>;
  carisozlesmeler_363646_value_kuikaSelectBoxRef: React.RefObject<any>;
  carisozlesmeler_142805_value_kuikaDatePickerRef: React.RefObject<any>;
  carisozlesmeler_126564_value_kuikaDatePickerRef: React.RefObject<any>;
  carisozlesmeler_754175_value_kuikaDatePickerRef: React.RefObject<any>;
  carisozlesmeler_174510_value_kuikaMultiSelectRef: React.RefObject<any>;
  carisozlesmeler_473179_value_kuikaSelectBoxRef: React.RefObject<any>;
  carisozlesmeler_454349_value_kuikaDateRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "93aed380-0d17-418f-ab4b-4401fa45e426",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 599445, PropertyName: "value", Value: "Hatırlatma Maili Gönderme" },
        { Id: 608676, PropertyName: "label", Value: "Kaydet" },
        { Id: 481539, PropertyName: "label", Value: "Kaydet" },
        { Id: 627305, PropertyName: "value", Value: "Yıl" },
        { Id: 90849, PropertyName: "placeholder", Value: "..." },
        { Id: 259334, PropertyName: "value", Value: "Alan" },
        { Id: 173622, PropertyName: "placeholder", Value: "..." },
        { Id: 542977, PropertyName: "value", Value: "Konu" },
        { Id: 838135, PropertyName: "value", Value: "Sözleşme Türü" },
        { Id: 61244, PropertyName: "value", Value: "Sözleşme Tarihi" },
        { Id: 142805, PropertyName: "placeholder", Value: "..." },
        { Id: 856150, PropertyName: "value", Value: "Başlangıç Tarihi" },
        { Id: 126564, PropertyName: "placeholder", Value: "..." },
        { Id: 929635, PropertyName: "value", Value: "Bitiş Tarihi" },
        { Id: 754175, PropertyName: "placeholder", Value: "..." },
        { Id: 885944, PropertyName: "value", Value: "Lisans Bölgesi" },
        { Id: 43237, PropertyName: "value", Value: "Toplam Tutar" },
        { Id: 840091, PropertyName: "value", Value: "Fatura Tarihi" },
        { Id: 94456, PropertyName: "value", Value: "Faturalama Planı" },
        { Id: 865309, PropertyName: "value", Value: "Fatura Tutarı" },
        { Id: 918700, PropertyName: "label", Value: "PDF Dosya Yükle" },
        { Id: 239690, PropertyName: "placeholder", Value: "..." }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.carisozlesmeler_90849_value_kuikaTextInputRef = React.createRef();
    this.carisozlesmeler_173622_value_kuikaTextInputRef = React.createRef();
    this.carisozlesmeler_186947_value_kuikaSelectBoxRef = React.createRef();
    this.carisozlesmeler_363646_value_kuikaSelectBoxRef = React.createRef();
    this.carisozlesmeler_142805_value_kuikaDatePickerRef = React.createRef();
    this.carisozlesmeler_126564_value_kuikaDatePickerRef = React.createRef();
    this.carisozlesmeler_754175_value_kuikaDatePickerRef = React.createRef();
    this.carisozlesmeler_174510_value_kuikaMultiSelectRef = React.createRef();
    this.carisozlesmeler_473179_value_kuikaSelectBoxRef = React.createRef();
    this.carisozlesmeler_454349_value_kuikaDateRef = React.createRef();
    this.carisozlesmeler_720644_value_kuikaTextInputRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      setKartCariSozlesmeBilgileriSave: 0,
      GoBack: "",
      sbSozlesmeTipiList: [],
      sbSozlesmeKonuList: [],
      sbTemsilBolgesiList: [],
      getKartCariSozlesmeBilgi: [],
      sbParaBirim: [],
      ChangeVisibilityOf: "",
      ChangeEnabledOf: "",
      spSozlesmeDonemTutarHesapla: [],
      SetValueOf: "",
      spSozlesmeFaturaDagit: [],
      Notify: false,
      UploadFile: "",
      TriggerEvent: "",
      isComp694470Visible: "visible",
      isComp308943Visible: "visible",
      isComp518562Visible: "hidden",
      isComp138538Visible: "hidden",
      isComp437788Visible: "hidden",
      isComp890030Visible: "hidden",
      isComp311330Visible: "hidden",
      isComp207335Visible: "hidden",
      isComp608676Visible: "hidden",
      isComp481539Visible: "hidden",
      isComp159164Visible: "hidden",
      isComp239690Visible: "hidden",
      isComp720644Enabled: "enabled",
      isComp701247Enabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("carisozlesmeler", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.CariSozlesmelerPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("carisozlesmeler", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("carisozlesmeler", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.CariSozlesmelerPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form?.setFieldsValue({
      carisozlesmeler_769681_value: this.state.getKartCariSozlesmeBilgi?.at?.(0)?.hatirlatma ?? undefined,
      carisozlesmeler_90849_value: this.state.getKartCariSozlesmeBilgi?.at?.(0)?.yilDonem ?? undefined,
      carisozlesmeler_173622_value: this.state.getKartCariSozlesmeBilgi?.at?.(0)?.alan ?? undefined,
      carisozlesmeler_186947_value: this.state.getKartCariSozlesmeBilgi?.at?.(0)?.sozlesmeKonuID ?? undefined,
      carisozlesmeler_363646_value: this.state.getKartCariSozlesmeBilgi?.at?.(0)?.sozlesmeTipiID ?? undefined,
      carisozlesmeler_142805_value: this.state.getKartCariSozlesmeBilgi?.at?.(0)?.sozlesmeTarihi ?? undefined,
      carisozlesmeler_126564_value: this.state.getKartCariSozlesmeBilgi?.at?.(0)?.basTarihi ?? undefined,
      carisozlesmeler_754175_value: this.state.getKartCariSozlesmeBilgi?.at?.(0)?.bitTarihi ?? undefined,
      carisozlesmeler_174510_value: this.state.getKartCariSozlesmeBilgi?.at?.(0)?.temsilBolgesiID ?? undefined,
      carisozlesmeler_168928_value: this.state.getKartCariSozlesmeBilgi?.at?.(0)?.toplamTutar ?? undefined,
      carisozlesmeler_473179_value: this.state.getKartCariSozlesmeBilgi?.at?.(0)?.paraBirimID ?? undefined,
      carisozlesmeler_454349_value: this.state.getKartCariSozlesmeBilgi?.at?.(0)?.faturaTarihi ?? undefined,
      carisozlesmeler_477478_value: this.state.getKartCariSozlesmeBilgi?.at?.(0)?.donemSayisi ?? undefined,
      carisozlesmeler_701247_value: this.state.getKartCariSozlesmeBilgi?.at?.(0)?.donemTutar ?? undefined,
      carisozlesmeler_720644_value: this.state.getKartCariSozlesmeBilgi?.at?.(0)?.dosyaAd ?? undefined,
      carisozlesmeler_239690_value: this.state.getKartCariSozlesmeBilgi?.at?.(0)?.dosya ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form?.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  CariSozlesmelerPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CariSozlesmeler/CariSozlesmelerPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.sbSozlesmeTipiList = result?.data.sbSozlesmeTipiList;

    formVars.carisozlesmeler_363646_value =
      stateVars.getKartCariSozlesmeBilgi?.length > 0
        ? stateVars.getKartCariSozlesmeBilgi[0].sozlesmeTipiID
        : this.state.getKartCariSozlesmeBilgi?.length > 0
        ? this.state.getKartCariSozlesmeBilgi[0].sozlesmeTipiID
        : null;
    formVars.carisozlesmeler_363646_options = stateVars.sbSozlesmeTipiList;
    stateVars.sbSozlesmeKonuList = result?.data.sbSozlesmeKonuList;

    formVars.carisozlesmeler_186947_value =
      stateVars.getKartCariSozlesmeBilgi?.length > 0
        ? stateVars.getKartCariSozlesmeBilgi[0].sozlesmeKonuID
        : this.state.getKartCariSozlesmeBilgi?.length > 0
        ? this.state.getKartCariSozlesmeBilgi[0].sozlesmeKonuID
        : null;
    formVars.carisozlesmeler_186947_options = stateVars.sbSozlesmeKonuList;
    stateVars.sbTemsilBolgesiList = result?.data.sbTemsilBolgesiList;

    formVars.carisozlesmeler_174510_options = stateVars.sbTemsilBolgesiList;
    formVars.carisozlesmeler_174510_value =
      stateVars.getKartCariSozlesmeBilgi?.length > 0
        ? stateVars.getKartCariSozlesmeBilgi[0].temsilBolgesiID
        : this.state.getKartCariSozlesmeBilgi?.length > 0
        ? this.state.getKartCariSozlesmeBilgi[0].temsilBolgesiID
        : null;
    stateVars.getKartCariSozlesmeBilgi = result?.data.getKartCariSozlesmeBilgi;
    formVars.carisozlesmeler_769681_value = ReactSystemFunctions.value(
      this,
      stateVars.getKartCariSozlesmeBilgi?.length > 0
        ? stateVars.getKartCariSozlesmeBilgi[0].hatirlatma
        : this.state.getKartCariSozlesmeBilgi?.length > 0
        ? this.state.getKartCariSozlesmeBilgi[0].hatirlatma
        : null
    );
    formVars.carisozlesmeler_90849_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartCariSozlesmeBilgi?.length > 0
        ? stateVars.getKartCariSozlesmeBilgi[0].yilDonem
        : this.state.getKartCariSozlesmeBilgi?.length > 0
        ? this.state.getKartCariSozlesmeBilgi[0].yilDonem
        : null
    );
    formVars.carisozlesmeler_173622_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartCariSozlesmeBilgi?.length > 0
        ? stateVars.getKartCariSozlesmeBilgi[0].alan
        : this.state.getKartCariSozlesmeBilgi?.length > 0
        ? this.state.getKartCariSozlesmeBilgi[0].alan
        : null
    );
    formVars.carisozlesmeler_186947_value =
      stateVars.getKartCariSozlesmeBilgi?.length > 0
        ? stateVars.getKartCariSozlesmeBilgi[0].sozlesmeKonuID
        : this.state.getKartCariSozlesmeBilgi?.length > 0
        ? this.state.getKartCariSozlesmeBilgi[0].sozlesmeKonuID
        : null;
    formVars.carisozlesmeler_186947_options = stateVars.sbSozlesmeKonuList;
    formVars.carisozlesmeler_363646_value =
      stateVars.getKartCariSozlesmeBilgi?.length > 0
        ? stateVars.getKartCariSozlesmeBilgi[0].sozlesmeTipiID
        : this.state.getKartCariSozlesmeBilgi?.length > 0
        ? this.state.getKartCariSozlesmeBilgi[0].sozlesmeTipiID
        : null;
    formVars.carisozlesmeler_363646_options = stateVars.sbSozlesmeTipiList;
    formVars.carisozlesmeler_142805_value = ReactSystemFunctions.value(
      this,
      stateVars.getKartCariSozlesmeBilgi?.length > 0
        ? stateVars.getKartCariSozlesmeBilgi[0].sozlesmeTarihi
        : this.state.getKartCariSozlesmeBilgi?.length > 0
        ? this.state.getKartCariSozlesmeBilgi[0].sozlesmeTarihi
        : null
    );
    formVars.carisozlesmeler_126564_value = ReactSystemFunctions.value(
      this,
      stateVars.getKartCariSozlesmeBilgi?.length > 0
        ? stateVars.getKartCariSozlesmeBilgi[0].basTarihi
        : this.state.getKartCariSozlesmeBilgi?.length > 0
        ? this.state.getKartCariSozlesmeBilgi[0].basTarihi
        : null
    );
    formVars.carisozlesmeler_754175_value = ReactSystemFunctions.value(
      this,
      stateVars.getKartCariSozlesmeBilgi?.length > 0
        ? stateVars.getKartCariSozlesmeBilgi[0].bitTarihi
        : this.state.getKartCariSozlesmeBilgi?.length > 0
        ? this.state.getKartCariSozlesmeBilgi[0].bitTarihi
        : null
    );
    formVars.carisozlesmeler_174510_options = stateVars.sbTemsilBolgesiList;
    formVars.carisozlesmeler_174510_value =
      stateVars.getKartCariSozlesmeBilgi?.length > 0
        ? stateVars.getKartCariSozlesmeBilgi[0].temsilBolgesiID
        : this.state.getKartCariSozlesmeBilgi?.length > 0
        ? this.state.getKartCariSozlesmeBilgi[0].temsilBolgesiID
        : null;
    formVars.carisozlesmeler_168928_value = ReactSystemFunctions.value(
      this,
      stateVars.getKartCariSozlesmeBilgi?.length > 0
        ? stateVars.getKartCariSozlesmeBilgi[0].toplamTutar
        : this.state.getKartCariSozlesmeBilgi?.length > 0
        ? this.state.getKartCariSozlesmeBilgi[0].toplamTutar
        : null
    );
    formVars.carisozlesmeler_473179_value =
      stateVars.getKartCariSozlesmeBilgi?.length > 0
        ? stateVars.getKartCariSozlesmeBilgi[0].paraBirimID
        : this.state.getKartCariSozlesmeBilgi?.length > 0
        ? this.state.getKartCariSozlesmeBilgi[0].paraBirimID
        : null;
    formVars.carisozlesmeler_473179_options = stateVars.sbParaBirim;
    formVars.carisozlesmeler_454349_value = ReactSystemFunctions.value(
      this,
      stateVars.getKartCariSozlesmeBilgi?.length > 0
        ? stateVars.getKartCariSozlesmeBilgi[0].faturaTarihi
        : this.state.getKartCariSozlesmeBilgi?.length > 0
        ? this.state.getKartCariSozlesmeBilgi[0].faturaTarihi
        : null
    );
    formVars.carisozlesmeler_477478_value = ReactSystemFunctions.value(
      this,
      stateVars.getKartCariSozlesmeBilgi?.length > 0
        ? stateVars.getKartCariSozlesmeBilgi[0].donemSayisi
        : this.state.getKartCariSozlesmeBilgi?.length > 0
        ? this.state.getKartCariSozlesmeBilgi[0].donemSayisi
        : null
    );
    formVars.carisozlesmeler_701247_value = ReactSystemFunctions.value(
      this,
      stateVars.getKartCariSozlesmeBilgi?.length > 0
        ? stateVars.getKartCariSozlesmeBilgi[0].donemTutar
        : this.state.getKartCariSozlesmeBilgi?.length > 0
        ? this.state.getKartCariSozlesmeBilgi[0].donemTutar
        : null
    );
    formVars.carisozlesmeler_720644_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartCariSozlesmeBilgi?.length > 0
        ? stateVars.getKartCariSozlesmeBilgi[0].dosyaAd
        : this.state.getKartCariSozlesmeBilgi?.length > 0
        ? this.state.getKartCariSozlesmeBilgi[0].dosyaAd
        : null
    );
    formVars.carisozlesmeler_239690_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartCariSozlesmeBilgi?.length > 0
        ? stateVars.getKartCariSozlesmeBilgi[0].dosya
        : this.state.getKartCariSozlesmeBilgi?.length > 0
        ? this.state.getKartCariSozlesmeBilgi[0].dosya
        : null
    );
    stateVars.sbParaBirim = result?.data.sbParaBirim;

    formVars.carisozlesmeler_473179_value =
      stateVars.getKartCariSozlesmeBilgi?.length > 0
        ? stateVars.getKartCariSozlesmeBilgi[0].paraBirimID
        : this.state.getKartCariSozlesmeBilgi?.length > 0
        ? this.state.getKartCariSozlesmeBilgi[0].paraBirimID
        : null;
    formVars.carisozlesmeler_473179_options = stateVars.sbParaBirim;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CariSozlesmelerPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CariSozlesmelerPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "cari"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp694470Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CariSozlesmelerPageInit2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CariSozlesmelerPageInit2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  CariSozlesmelerPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(
      this,
      ReactSystemFunctions.convertToTypeByName("isComp720644Enabled", "string"),
      ReactSystemFunctions.convertToTypeByName("disabled", "string")
    );
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(
      this,
      ReactSystemFunctions.convertToTypeByName("isComp701247Enabled", "string"),
      ReactSystemFunctions.convertToTypeByName("disabled", "string")
    );

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CariSozlesmelerPageInit3_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  CariSozlesmelerPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmIsKartTuruID ?? this.props.screenInputs.prmiskartturuid,
        "00000000-0000-0000-0000-000000000002"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp308943Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CariSozlesmelerPageInit4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CariSozlesmelerPageInit4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  CariSozlesmelerPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmIsKartTuruID ?? this.props.screenInputs.prmiskartturuid,
        "00000000-0000-0000-0000-000000000001"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp518562Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CariSozlesmelerPageInit5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CariSozlesmelerPageInit5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  CariSozlesmelerPageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmIsKartTuruID ?? this.props.screenInputs.prmiskartturuid,
        "00000000-0000-0000-0000-000000000001"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp138538Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CariSozlesmelerPageInit6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CariSozlesmelerPageInit6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  CariSozlesmelerPageInit6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmIsKartTuruID ?? this.props.screenInputs.prmiskartturuid,
        "00000000-0000-0000-0000-000000000001"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp437788Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CariSozlesmelerPageInit7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CariSozlesmelerPageInit7_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  CariSozlesmelerPageInit7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmIsKartTuruID ?? this.props.screenInputs.prmiskartturuid,
        "00000000-0000-0000-0000-000000000001"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp890030Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CariSozlesmelerPageInit8_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CariSozlesmelerPageInit8_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  CariSozlesmelerPageInit8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmIsKartTuruID ?? this.props.screenInputs.prmiskartturuid,
        "00000000-0000-0000-0000-000000000001"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp311330Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CariSozlesmelerPageInit9_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CariSozlesmelerPageInit9_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  CariSozlesmelerPageInit9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "cari"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp518562Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CariSozlesmelerPageInit10_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CariSozlesmelerPageInit10_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  CariSozlesmelerPageInit10_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "cari"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp138538Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CariSozlesmelerPageInit11_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CariSozlesmelerPageInit11_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  CariSozlesmelerPageInit11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "cari"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp437788Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CariSozlesmelerPageInit12_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CariSozlesmelerPageInit12_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  CariSozlesmelerPageInit12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "cari"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp890030Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CariSozlesmelerPageInit13_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CariSozlesmelerPageInit13_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  CariSozlesmelerPageInit13_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "cari"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp311330Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CariSozlesmelerPageInit14_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CariSozlesmelerPageInit14_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  CariSozlesmelerPageInit14_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.carisozlesmeler_769681_value = ReactSystemFunctions.value(
      this,
      stateVars.getKartCariSozlesmeBilgi?.length > 0
        ? stateVars.getKartCariSozlesmeBilgi[0].hatirlatma
        : this.state.getKartCariSozlesmeBilgi?.length > 0
        ? this.state.getKartCariSozlesmeBilgi[0].hatirlatma
        : null
    );

    stateVars.isComp608676Visible =
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "sozlesme"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp481539Visible =
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "cari"
      ) === true
        ? "visible"
        : "hidden";
    formVars.carisozlesmeler_90849_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartCariSozlesmeBilgi?.length > 0
        ? stateVars.getKartCariSozlesmeBilgi[0].yilDonem
        : this.state.getKartCariSozlesmeBilgi?.length > 0
        ? this.state.getKartCariSozlesmeBilgi[0].yilDonem
        : null
    );

    formVars.carisozlesmeler_173622_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartCariSozlesmeBilgi?.length > 0
        ? stateVars.getKartCariSozlesmeBilgi[0].alan
        : this.state.getKartCariSozlesmeBilgi?.length > 0
        ? this.state.getKartCariSozlesmeBilgi[0].alan
        : null
    );

    formVars.carisozlesmeler_186947_value = ReactSystemFunctions.value(
      this,
      stateVars.getKartCariSozlesmeBilgi?.length > 0
        ? stateVars.getKartCariSozlesmeBilgi[0].sozlesmeKonuID
        : this.state.getKartCariSozlesmeBilgi?.length > 0
        ? this.state.getKartCariSozlesmeBilgi[0].sozlesmeKonuID
        : null
    );

    stateVars.dataSource_186947 = this.state.sbSozlesmeKonuList;
    stateVars.dataSource_186947 = this.state.sbSozlesmeKonuList;
    formVars.carisozlesmeler_363646_value = ReactSystemFunctions.value(
      this,
      stateVars.getKartCariSozlesmeBilgi?.length > 0
        ? stateVars.getKartCariSozlesmeBilgi[0].sozlesmeTipiID
        : this.state.getKartCariSozlesmeBilgi?.length > 0
        ? this.state.getKartCariSozlesmeBilgi[0].sozlesmeTipiID
        : null
    );

    stateVars.dataSource_363646 = this.state.sbSozlesmeTipiList;
    stateVars.dataSource_363646 = this.state.sbSozlesmeTipiList;
    formVars.carisozlesmeler_142805_value = ReactSystemFunctions.value(
      this,
      stateVars.getKartCariSozlesmeBilgi?.length > 0
        ? stateVars.getKartCariSozlesmeBilgi[0].sozlesmeTarihi
        : this.state.getKartCariSozlesmeBilgi?.length > 0
        ? this.state.getKartCariSozlesmeBilgi[0].sozlesmeTarihi
        : null
    );

    formVars.carisozlesmeler_126564_value = ReactSystemFunctions.value(
      this,
      stateVars.getKartCariSozlesmeBilgi?.length > 0
        ? stateVars.getKartCariSozlesmeBilgi[0].basTarihi
        : this.state.getKartCariSozlesmeBilgi?.length > 0
        ? this.state.getKartCariSozlesmeBilgi[0].basTarihi
        : null
    );

    formVars.carisozlesmeler_754175_value = ReactSystemFunctions.value(
      this,
      stateVars.getKartCariSozlesmeBilgi?.length > 0
        ? stateVars.getKartCariSozlesmeBilgi[0].bitTarihi
        : this.state.getKartCariSozlesmeBilgi?.length > 0
        ? this.state.getKartCariSozlesmeBilgi[0].bitTarihi
        : null
    );

    formVars.carisozlesmeler_174510_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartCariSozlesmeBilgi?.length > 0
        ? stateVars.getKartCariSozlesmeBilgi[0].temsilBolgesiID
        : this.state.getKartCariSozlesmeBilgi?.length > 0
        ? this.state.getKartCariSozlesmeBilgi[0].temsilBolgesiID
        : null
    );

    stateVars.dataSource_174510 = this.state.sbTemsilBolgesiList;
    stateVars.dataSource_174510 = this.state.sbTemsilBolgesiList;
    stateVars.isComp159164Visible =
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "sozlesme"
      ) === true
        ? "visible"
        : "hidden";
    formVars.carisozlesmeler_168928_value = ReactSystemFunctions.value(
      this,
      stateVars.getKartCariSozlesmeBilgi?.length > 0
        ? stateVars.getKartCariSozlesmeBilgi[0].toplamTutar
        : this.state.getKartCariSozlesmeBilgi?.length > 0
        ? this.state.getKartCariSozlesmeBilgi[0].toplamTutar
        : null
    );

    formVars.carisozlesmeler_473179_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartCariSozlesmeBilgi?.length > 0
        ? stateVars.getKartCariSozlesmeBilgi[0].paraBirimID
        : this.state.getKartCariSozlesmeBilgi?.length > 0
        ? this.state.getKartCariSozlesmeBilgi[0].paraBirimID
        : null
    );

    stateVars.dataSource_473179 = this.state.sbParaBirim;
    stateVars.dataSource_473179 = this.state.sbParaBirim;
    formVars.carisozlesmeler_454349_value = ReactSystemFunctions.value(
      this,
      stateVars.getKartCariSozlesmeBilgi?.length > 0
        ? stateVars.getKartCariSozlesmeBilgi[0].faturaTarihi
        : this.state.getKartCariSozlesmeBilgi?.length > 0
        ? this.state.getKartCariSozlesmeBilgi[0].faturaTarihi
        : null
    );

    formVars.carisozlesmeler_477478_value = ReactSystemFunctions.value(
      this,
      stateVars.getKartCariSozlesmeBilgi?.length > 0
        ? stateVars.getKartCariSozlesmeBilgi[0].donemSayisi
        : this.state.getKartCariSozlesmeBilgi?.length > 0
        ? this.state.getKartCariSozlesmeBilgi[0].donemSayisi
        : null
    );

    formVars.carisozlesmeler_701247_value = ReactSystemFunctions.value(
      this,
      stateVars.getKartCariSozlesmeBilgi?.length > 0
        ? stateVars.getKartCariSozlesmeBilgi[0].donemTutar
        : this.state.getKartCariSozlesmeBilgi?.length > 0
        ? this.state.getKartCariSozlesmeBilgi[0].donemTutar
        : null
    );

    formVars.carisozlesmeler_720644_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartCariSozlesmeBilgi?.length > 0
        ? stateVars.getKartCariSozlesmeBilgi[0].dosyaAd
        : this.state.getKartCariSozlesmeBilgi?.length > 0
        ? this.state.getKartCariSozlesmeBilgi[0].dosyaAd
        : null
    );

    stateVars.isComp207335Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_239690_value", "value", "", "", "")
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.carisozlesmeler_239690_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartCariSozlesmeBilgi?.length > 0
        ? stateVars.getKartCariSozlesmeBilgi[0].dosya
        : this.state.getKartCariSozlesmeBilgi?.length > 0
        ? this.state.getKartCariSozlesmeBilgi[0].dosya
        : null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  CariSozlesmelerComponent_608676_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_477478_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "carisozlesmeler_477478_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_701247_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "carisozlesmeler_701247_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_454349_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "carisozlesmeler_454349_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_473179_value", "value", "sbParaBirim", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "carisozlesmeler_473179_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_168928_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "carisozlesmeler_168928_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Alan_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_173622_value", "value", "", "", "")
        ),
        "string"
      ),
      BasTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_126564_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_754175_value", "value", "", "", "")
        ),
        "Date"
      ),
      CariID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmCariID ?? this.props.screenInputs.prmcariid,
        "Guid"
      ),
      DonemSayisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_477478_value", "value", "", "", "")
        ),
        "number"
      ),
      DonemTutar_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_701247_value", "value", "", "", "")
        ),
        "number"
      ),
      Dosya_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_239690_value", "value", "", "", "")
        ),
        "string"
      ),
      DosyaAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_720644_value", "value", "", "", "")
        ),
        "string"
      ),
      FaturaTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_454349_value", "value", "", "", "")
        ),
        "Date"
      ),
      Konu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_186947_value", "value", "sbSozlesmeKonuList", "id", "id")
        ),
        "string"
      ),
      ParaBirimID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_473179_value", "value", "sbParaBirim", "id", "id")
        ),
        "Guid"
      ),
      SozlesmeKonuID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_186947_value", "value", "sbSozlesmeKonuList", "id", "id")
        ),
        "Guid"
      ),
      SozlesmeTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_142805_value", "value", "", "", "")
        ),
        "Date"
      ),
      SozlesmeTipiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_363646_value", "value", "sbSozlesmeTipiList", "id", "id")
        ),
        "Guid"
      ),
      TemsilBolgesiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_174510_value", "value", "sbTemsilBolgesiList", "id", "id")
        ),
        "string"
      ),
      ToplamTutar_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_168928_value", "value", "", "", "")
        ),
        "number"
      ),
      YilDonem_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_90849_value", "value", "", "", "")
        ),
        "string"
      ),
      Hatirlatma_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_769681_checked", "value", "", "", "")
        ),
        "boolean"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CariSozlesmeler/CariSozlesmelerComponent_608676_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.setKartCariSozlesmeBilgileriSave = result?.data.setKartCariSozlesmeBilgileriSave;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CariSozlesmelerComponent_608676_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CariSozlesmelerComponent_608676_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CariSozlesmelerComponent_481539_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Alan_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_173622_value", "value", "", "", "")
        ),
        "string"
      ),
      BasTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_126564_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_754175_value", "value", "", "", "")
        ),
        "Date"
      ),
      CariID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmCariID ?? this.props.screenInputs.prmcariid,
        "Guid"
      ),
      DonemSayisi_0: ReactSystemFunctions.convertToTypeByName(null, "number"),
      DonemTutar_0: ReactSystemFunctions.convertToTypeByName(null, "number"),
      Dosya_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_239690_value", "value", "", "", "")
        ),
        "string"
      ),
      DosyaAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_720644_value", "value", "", "", "")
        ),
        "string"
      ),
      FaturaTarihi_0: ReactSystemFunctions.convertToTypeByName(null, "Date"),
      Konu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_186947_value", "value", "sbSozlesmeKonuList", "id", "id")
        ),
        "string"
      ),
      ParaBirimID_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      SozlesmeKonuID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_186947_value", "value", "sbSozlesmeKonuList", "id", "id")
        ),
        "Guid"
      ),
      SozlesmeTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_142805_value", "value", "", "", "")
        ),
        "Date"
      ),
      SozlesmeTipiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_363646_value", "value", "sbSozlesmeTipiList", "id", "id")
        ),
        "Guid"
      ),
      TemsilBolgesiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_174510_value", "value", "sbTemsilBolgesiList", "id", "id")
        ),
        "string"
      ),
      ToplamTutar_0: ReactSystemFunctions.convertToTypeByName(null, "number"),
      YilDonem_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_90849_value", "value", "", "", "")
        ),
        "string"
      ),
      Hatirlatma_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_769681_checked", "value", "", "", "")
        ),
        "boolean"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CariSozlesmeler/CariSozlesmelerComponent_481539_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.setKartCariSozlesmeBilgileriSave = result?.data.setKartCariSozlesmeBilgileriSave;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CariSozlesmelerComponent_481539_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CariSozlesmelerComponent_481539_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CariSozlesmelerComponent_116856_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CariSozlesmelerComponent_168928_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_477478_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "carisozlesmeler_477478_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_168928_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "carisozlesmeler_168928_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      DonemSayisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_477478_value", "value", "", "", "")
        ),
        "number"
      ),
      ToplamTutar_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_168928_value", "value", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CariSozlesmeler/CariSozlesmelerComponent_168928_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spSozlesmeDonemTutarHesapla = result?.data.spSozlesmeDonemTutarHesapla;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CariSozlesmelerComponent_168928_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CariSozlesmelerComponent_168928_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("carisozlesmeler_701247_value", "string"),
      ReactSystemFunctions.value(
        this,
        stateVars.spSozlesmeDonemTutarHesapla?.length > 0
          ? stateVars.spSozlesmeDonemTutarHesapla[0].sonuc
          : this.state.spSozlesmeDonemTutarHesapla?.length > 0
          ? this.state.spSozlesmeDonemTutarHesapla[0].sonuc
          : null
      ),
      null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CariSozlesmelerComponent_477478_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_477478_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "carisozlesmeler_477478_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_168928_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "carisozlesmeler_168928_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      DonemSayisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_477478_value", "value", "", "", "")
        ),
        "number"
      ),
      ToplamTutar_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_168928_value", "value", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CariSozlesmeler/CariSozlesmelerComponent_477478_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spSozlesmeDonemTutarHesapla = result?.data.spSozlesmeDonemTutarHesapla;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CariSozlesmelerComponent_477478_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CariSozlesmelerComponent_477478_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("carisozlesmeler_701247_value", "string"),
      ReactSystemFunctions.value(
        this,
        stateVars.spSozlesmeDonemTutarHesapla?.length > 0
          ? stateVars.spSozlesmeDonemTutarHesapla[0].sonuc
          : this.state.spSozlesmeDonemTutarHesapla?.length > 0
          ? this.state.spSozlesmeDonemTutarHesapla[0].sonuc
          : null
      ),
      null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CariSozlesmelerComponent_43798_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_477478_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "carisozlesmeler_477478_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_701247_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "carisozlesmeler_701247_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_454349_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "carisozlesmeler_454349_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_473179_value", "value", "sbParaBirim", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "carisozlesmeler_473179_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_168928_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "carisozlesmeler_168928_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Alan_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_173622_value", "value", "", "", "")
        ),
        "string"
      ),
      BasTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_126564_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_754175_value", "value", "", "", "")
        ),
        "Date"
      ),
      CariID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmCariID ?? this.props.screenInputs.prmcariid,
        "Guid"
      ),
      DonemSayisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_477478_value", "value", "", "", "")
        ),
        "number"
      ),
      DonemTutar_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_701247_value", "value", "", "", "")
        ),
        "number"
      ),
      Dosya_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_239690_value", "value", "", "", "")
        ),
        "string"
      ),
      DosyaAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_720644_value", "value", "", "", "")
        ),
        "string"
      ),
      FaturaTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_454349_value", "value", "", "", "")
        ),
        "Date"
      ),
      Konu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_186947_value", "value", "sbSozlesmeKonuList", "id", "id")
        ),
        "string"
      ),
      ParaBirimID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_473179_value", "value", "sbParaBirim", "id", "id")
        ),
        "Guid"
      ),
      SozlesmeKonuID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_186947_value", "value", "sbSozlesmeKonuList", "id", "id")
        ),
        "Guid"
      ),
      SozlesmeTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_142805_value", "value", "", "", "")
        ),
        "Date"
      ),
      SozlesmeTipiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_363646_value", "value", "sbSozlesmeTipiList", "id", "id")
        ),
        "Guid"
      ),
      TemsilBolgesiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_174510_value", "value", "sbTemsilBolgesiList", "id", "id")
        ),
        "string"
      ),
      ToplamTutar_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_168928_value", "value", "", "", "")
        ),
        "number"
      ),
      YilDonem_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_90849_value", "value", "", "", "")
        ),
        "string"
      ),
      Hatirlatma_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_769681_checked", "value", "", "", "")
        ),
        "boolean"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      SozlesmeID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "string"
      ),
      IsKartTuruID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmIsKartTuruID ?? this.props.screenInputs.prmiskartturuid,
        "string"
      ),
      AksiyonIlgiliKisi_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      CariID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmMusteriID ?? this.props.screenInputs.prmmusteriid,
        "string"
      ),
      DonemSayisi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_477478_value", "value", "", "", "")
        ),
        "number"
      ),
      FaturaTarihi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_454349_value", "value", "", "", "")
        ),
        "Date"
      ),
      FaturaTutar_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_701247_value", "value", "", "", "")
        ),
        "number"
      ),
      ImportKartPaketAnlasmaID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmCariID ?? this.props.screenInputs.prmcariid,
        "string"
      ),
      Donem_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_90849_value", "value", "", "", "")
        ),
        "string"
      ),
      ParaBirimID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_473179_value", "value", "sbParaBirim", "id", "id")
        ),
        "string"
      ),
      SozlesmeTarihi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_142805_value", "value", "", "", "")
        ),
        "Date"
      ),
      LisansBasTarihi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_126564_value", "value", "", "", "")
        ),
        "Date"
      ),
      LisansBitTarihi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_754175_value", "value", "", "", "")
        ),
        "Date"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CariSozlesmeler/CariSozlesmelerComponent_43798_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.setKartCariSozlesmeBilgileriSave = result?.data.setKartCariSozlesmeBilgileriSave;
    stateVars.spSozlesmeFaturaDagit = result?.data.spSozlesmeFaturaDagit;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CariSozlesmelerComponent_43798_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CariSozlesmelerComponent_43798_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "82b0fe19_fa7a_40e5_b603_cdc6eeaa81d3_notify",
        this.defaultML,
        "İş Kartları Oluşturuldu"
      ),
      ReactSystemFunctions.convertToTypeByName("success", "string"),
      ReactSystemFunctions.convertToTypeByName("bottom-right", "string"),
      ReactSystemFunctions.convertToTypeByName("1", "number"),
      null,
      null,
      null
    );
    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.toString(
        this,
        stateVars.spSozlesmeFaturaDagit?.length > 0
          ? stateVars.spSozlesmeFaturaDagit[0].ticketNo
          : this.state.spSozlesmeFaturaDagit?.length > 0
          ? this.state.spSozlesmeFaturaDagit[0].ticketNo
          : null
      ),
      ReactSystemFunctions.convertToTypeByName("info", "string"),
      ReactSystemFunctions.convertToTypeByName("bottom-right", "string"),
      ReactSystemFunctions.convertToTypeByName("2", "number"),
      null,
      null,
      null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CariSozlesmelerComponent_918700_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CariSozlesmelerComponent_918700_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  CariSozlesmelerComponent_918700_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        ReactSystemFunctions.convertToTypeByName("carisozlesmeler_720644_value", "string"),
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        null
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CariSozlesmelerComponent_918700_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CariSozlesmelerComponent_918700_onClick2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  CariSozlesmelerComponent_918700_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        ReactSystemFunctions.convertToTypeByName("carisozlesmeler_239690_value", "string"),
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        null
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CariSozlesmelerComponent_918700_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CariSozlesmelerComponent_918700_onClick3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  CariSozlesmelerComponent_918700_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp207335Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CariSozlesmelerComponent_918700_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CariSozlesmelerComponent_918700_onClick4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  CariSozlesmelerComponent_918700_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "b13d2033_44c1_1205_a2a2_4692d8754bb2_notify",
          this.defaultML,
          "Dosya Yüklendi"
        ),
        ReactSystemFunctions.convertToTypeByName("success", "string"),
        ReactSystemFunctions.convertToTypeByName("bottom-right", "string"),
        ReactSystemFunctions.convertToTypeByName("5", "number"),
        null,
        null,
        null
      );

      this.props.form?.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  CariSozlesmelerComponent_207335_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("carisozlesmeler_720644_value", "string"),
      null,
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("carisozlesmeler_239690_value", "string"),
      null,
      null
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      ReactSystemFunctions.convertToTypeByName("isComp207335Visible", "string"),
      ReactSystemFunctions.convertToTypeByName("hidden", "string")
    );
    if (await this.CariSozlesmelerComponent_608676_onClick()) return true;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CariSozlesmelerComponent_239690_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.isComp207335Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "carisozlesmeler_239690_value", "value", "", "", "")
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
